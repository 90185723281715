import { FormGroup, ValidationErrors } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { field, getValidatorErrorMessage, validatorErrorMessage } from '@utils/validations';

export class AbstractFormComponent {
  showErrors(form?: FormGroup, fieldName?: string): boolean {
    if (fieldName == null) return false;
    const field = form?.controls[fieldName];
    return (field?.touched === true && !!field?.errors) || false;
  }

  getError(form?: FormGroup, fieldName?: string, config: {
    displayFieldName: boolean,
  } = { displayFieldName: false }): string {
    let message = "";

    if (fieldName == null) return message;

    const errors = form?.controls[fieldName].errors;
    if (errors === null || errors === undefined) return message;

    Object.keys(errors).forEach((errorName: string) => {
      message = this.getErrorMessage(
        errors,
        errorName as keyof typeof validatorErrorMessage,
        config.displayFieldName ? fieldName as keyof typeof field : undefined
      )
    })

    return message;
  }

  getErrorMessage(errors: ValidationErrors, errorNameofValidatorErrorMessage: keyof typeof validatorErrorMessage, fieldName?: keyof typeof field): string {
    if (typeof errors[errorNameofValidatorErrorMessage] === 'boolean') {
      return getValidatorErrorMessage(errorNameofValidatorErrorMessage, {
        name: fieldName ?? undefined
      });
    }
    if (typeof errors[errorNameofValidatorErrorMessage] === "object") {
      return getValidatorErrorMessage(errorNameofValidatorErrorMessage, {
        bind: Object.values(errors[errorNameofValidatorErrorMessage]),
        name: fieldName ?? undefined
      });
    }
    return "";
  }

  showErrorToast(messageService: MessageService, message: string): void {
    messageService.add({
      severity: 'error',
      summary: 'Erreur',
      detail: message,
    });
  }
}
